<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Super777 Club - Online Fish Table and Slots Games Real Money'; //Super777 Club - Online Slots & Fish Table Real Money Games
        document.head.querySelector('meta[name=description]').content = to.meta.description || '';
        if(to.meta.jsonld){
          document.head.querySelector('script[type="application/ld+json"]').textContent = JSON.stringify(to.meta.jsonld);
        }else{
          document.head.querySelector('script[type="application/ld+json"]').textContent = JSON.stringify({});
        }
      }
    },
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
