import {
  cibBitcoin
} from '@coreui/icons'
import {
  cilDollar,
  cilEnvelopeClosed,
  cilHome,
  cilLockLocked,
  cilMoney,
  cilReload,
  cilShieldAlt,
  cilSpeech,
  cilTransfer,
  cilUser
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilDollar,
    cilEnvelopeClosed,
    cilHome,
    cilLockLocked,
    cilMoney,
    cilReload,
    cilShieldAlt,
    cilSpeech,
    cilTransfer,
    cilUser
  },
  {
    cibBitcoin
  }
)
