import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Home = () => import('@/views/Home')
const About = () => import('@/views/About')
const Faq = () => import('@/views/Faq')
const Promotions = () => import('@/views/Promotions')
const Games = () => import('@/views/Games')

// Manual Blog Entries (Temp)
const Post1 = () => import('@/views/post/Post1')
const Post2 = () => import('@/views/post/Post2')
const Post3 = () => import('@/views/post/Post3')
const Post4 = () => import('@/views/post/Post4')
const Post5 = () => import('@/views/post/Post5')
const Post6 = () => import('@/views/post/Post6')
const Post7 = () => import('@/views/post/Post7')
const Post8 = () => import('@/views/post/Post8')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/home',
      name: 'Default',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Home',
          component: Home,
          meta: {
            description: 'S777club is a system that specializes in providing online fish table & slot games products in the USA market.', 
            jsonld: {
              "@context":"https://schema.org",
              "@graph": [
                {
                  "@type": "WebSite",
                  "@id": "https://s777.club/",
                  "url": "https://s777.club/home",
                  "name": "Super777 Club - Online Fish Table and Slots Games Real Money",
                  "description": "S777club is a system that specializes in providing online fish table and slot games products in the USA market.",
                  "keywords":["fish tables online", "fish table gambling game online real money"],
                  "sameAs" : ["https://facebook.com/super777club", "https://twitter.com/s777club", "https://www.pinterest.com/s777clubb/", " https://www.youtube.com/channel/UCKgSztHs9Aeo8nf2PwbaN4w/"]
                },
                {
                  "@type":"Question",
                  "@id":"https://s777.club/#faq-question-1",
                  "position":1,
                  "url":"https://s777.club/#faq-question-1",
                  "name":"How do I create a Super777 Club Casino account?",
                  "answerCount":1,
                  "acceptedAnswer": {
                    "@type":"Answer",
                    "text":"In order to create an account, all you have to do is click on the 'Signup' button located in the top right corner of the webpage (for desktop) on the top left corner of the webpage (for mobile) and then enter your details in the fields provided. Once you have done this, you will receive a confirmation email with a link. You should click on this link in order to verify your account. Once these simple steps have been completed, you're good to go!",
                    "inLanguage":"en-US"
                  },
                  "inLanguage":"en-US"
                },
                {
                  "@type":"Question",
                  "@id":"https://s777.club/#faq-question-2",
                  "position":2,
                  "url":"https://s777.club/#faq-question-2",
                  "name":"What do I do if I have forgotten my password?",
                  "answerCount":2,
                  "acceptedAnswer": {
                    "@type":"Answer",
                    "text":"Well, it happens to the best of us, but it’s not the end of the world! The fastest way to reset your password is to click on the 'Login' button, located the 'Forgot your password?' link and then click on that. Once you have done this, you will receive an email from us which will take you to another page. Here you will be able to create a new password (preferably one you will remember!).",
                    "inLanguage":"en-US"
                  },
                  "inLanguage":"en-US"
                },
                {
                  "@type":"Question",
                  "@id":"https://s777.club/#faq-question-3",
                  "position":3,
                  "url":"https://s777.club/#faq-question-3",
                  "name":"What payment methods do you accept?",
                  "answerCount":3,
                  "acceptedAnswer": {
                    "@type":"Answer",
                    "text":"We are currently accepting payment via Bitcoin (BTC) and Cashapp. Direct credit / debit cards are not accepted due to the vast number of fraud cases we have encountered in the past.",
                    "inLanguage":"en-US"
                  },
                  "inLanguage":"en-US"
                }
              ]
            }
          }
        },
        {
          path: 'about',
          name: 'About',
          component: About,
          meta: { 
            title: 'Super777 Club - About Us',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/about",
              "url": "https://s777.club/about",
              "name": "Super777 Club - About Us",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'faq',
          name: 'Faq',
          component: Faq,
          meta: { 
            title: 'Super777 Club - Frequently Asked Questions FAQ',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/faq",
              "url": "https://s777.club/faq",
              "name": "Super777 Club - Frequently Asked Questions FAQ",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'promotions',
          name: 'Promotions',
          component: Promotions,
          meta: { 
            title: 'Super777 Club - Promotions',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/promotions",
              "url": "https://s777.club/promotions",
              "name": "Super777 Club - Promotions",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'games/:gametype',
          name: 'Games',
          component: Games,
          meta: {
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Super777 Club - Games",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
          //meta: { title: 'Super777 Club - Games' }
        },
        
        // Manual Blog Entries (Temp)
        {
          path: 'Air-Combat-1942',
          name: 'Air Combat 1942',
          component: Post1,
          meta: { 
            title: 'Super777 Club - Air Combat 1942',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/Air-Combat-1942",
              "url": "https://s777.club/Air-Combat-1942",
              "name": "Super777 Club - Air Combat 1942",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'How-To-Play-Golden-Dragon-Online-Fish-Table',
          name: 'How To Play Golden Dragon Online Fish Table',
          component: Post2,
          meta: { 
            title: 'Super777 Club - Golden Dragon Online Fish Table',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/How-To-Play-Golden-Dragon-Online-Fish-Table",
              "url": "https://s777.club/How-To-Play-Golden-Dragon-Online-Fish-Table",
              "name": "Super777 Club - Golden Dragon Online Fish Table",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'How-To-Play-Ocean-King-3-Fire-Phoenix',
          name: 'How To Play Ocean King 3 - Fire Phoenix',
          component: Post3,
          meta: { 
            title: 'Super777 Club - How To Play Ocean King 3 - Fire Phoenix',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/How-To-Play-Ocean-King-3-Fire-Phoenix",
              "url": "https://s777.club/How-To-Play-Ocean-King-3-Fire-Phoenix",
              "name": "Super777 Club - How To Play Ocean King 3 - Fire Phoenix",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'How-To-Play-Fishing-Foodie-At-S777-Club',
          name: 'How To Play Fishing Foodie At S777 Club',
          component: Post4,
          meta: { 
            title: 'Super777 Club - How To Play Fishing Foodie At S777 Club',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/How-To-Play-Fishing-Foodie-At-S777-Club",
              "url": "https://s777.club/How-To-Play-Fishing-Foodie-At-S777-Club",
              "name": "Super777 Club - How To Play Fishing Foodie At S777 Club",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'Introduction-About-Fishing-Expedition-At-S777-Club',
          name: 'Introduction About Fishing Expedition At S777 Club',
          component: Post5,
          meta: { 
            title: 'Super777 Club - Introduction About Fishing Expedition At S777 Club',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/Introduction-About-Fishing-Expedition-At-S777-Club",
              "url": "https://s777.club/Introduction-About-Fishing-Expedition-At-S777-Club",
              "name": "Super777 Club - Introduction About Fishing Expedition At S777 Club",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'Haidilao-Fish-Table-Games-Online',
          name: 'Haidilao - Fish Table Games Online',
          component: Post6,
          meta: { 
            title: 'Super777 Club - Haidilao - Fish Table Games Online',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/Haidilao-Fish-Table-Games-Online",
              "url": "https://s777.club/Haidilao-Fish-Table-Games-Online",
              "name": "Super777 Club - Haidilao - Fish Table Games Online",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'How-To-Play-Fish-Table-Online-Secret-In-Fish-Table-To-Get-Real-Money',
          name: 'How To Play Fish Table Online - Secret In Fish Table To Get Real Money',
          component: Post7,
          meta: { 
            title: 'How To Play Fish Table Online - Secret In Fish Table To Get Real Money',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/How-To-Play-Fish-Table-Online-Secret-In-Fish-Table-To-Get-Real-Money",
              "url": "https://s777.club/How-To-Play-Fish-Table-Online-Secret-In-Fish-Table-To-Get-Real-Money",
              "name": "Super777 Club - How To Play Fish Table Online - Secret In Fish Table To Get Real Money",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        },
        {
          path: 'Ocean-King-Online-Real-Money',
          name: 'Ocean King Online Real Money',
          component: Post8,
          meta: { 
            title: 'Ocean King Online Real Money',
            jsonld: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://s777.club/Ocean-King-Online-Real-Money",
              "url": "https://s777.club/Ocean-King-Online-Real-Money",
              "name": "Super777 Club - Ocean King Online Real Money",
              "description": "",
              "keywords":["fish tables online", "fish table gambling game online real money"]
            }
          }
        }

      ]
    }
  ]
}

