import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
//import CoreuiVue from '@coreui/vue'

import { CWrapper, CContainer, CModal, CForm, CInput, CTextarea, CButton, CHeader, CHeaderBrand, CHeaderNav, CHeaderNavItem, CHeaderNavLink, CSubheader, CNav, CNavItem,  CDropdown, CDropdownItem, CDropdownDivider, CIcon, CBadge, CLink, CJumbotron, CImg, CRow, CCol } from '@coreui/vue/src';

import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VueCurrencyFilter from 'vue-currency-filter' // currency filter
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueMobileDetection from 'vue-mobile-detection'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
const toastOptions = {}
import VueSkeletonLoader from 'skeleton-loader-vue'

Vue.config.performance = true
//Vue.use(CoreuiVue)
Vue.use(VueCurrencyFilter, { symbol: '$', thousandsSeparator: ',', fractionCount: 2, fractionSeparator: '.', symbolPosition: 'front', symbolSpacing: false })
Vue.use(VueFriendlyIframe)
Vue.use(VueMobileDetection)
Vue.use(Toast, toastOptions)
Vue.component('vue-skeleton-loader', VueSkeletonLoader)

Vue.component('CWrapper', CWrapper)
Vue.component('CContainer', CContainer)
Vue.component('CModal', CModal)
Vue.component('CForm', CForm)
Vue.component('CInput', CInput)
Vue.component('CTextarea', CTextarea)
Vue.component('CButton', CButton)
Vue.component('CHeader', CHeader)
Vue.component('CHeaderBrand', CHeaderBrand)
Vue.component('CHeaderNav', CHeaderNav)
Vue.component('CHeaderNavItem', CHeaderNavItem)
Vue.component('CHeaderNavLink', CHeaderNavLink)
Vue.component('CSubheader', CSubheader)
Vue.component('CNav', CNav)
Vue.component('CNavItem', CNavItem)
Vue.component('CDropdown', CDropdown)
Vue.component('CDropdownItem', CDropdownItem)
Vue.component('CDropdownDivider', CDropdownDivider)
Vue.component('CIcon', CIcon)
Vue.component('CBadge', CBadge)
Vue.component('CLink', CLink)
Vue.component('CJumbotron', CJumbotron)
Vue.component('CImg', CImg)
Vue.component('CRow', CRow)
Vue.component('CCol', CCol)

//Vue.prototype.$log = console.log.bind(console)

// Global plugin for noscroll on body (when modals are open)
const toggleBodyClassPlugin = {
  install() {
    Vue.prototype.$toggleBodyClass = function(addRemoveClass, className){
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
Vue.use(toggleBodyClassPlugin)


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
